<template>
  <el-table
    :data="permissions"
    border
    stripe
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="60px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="名称" prop="name" min-width="200px" />
    <el-table-column label="描述" prop="des" min-width="200px" />
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      permissions: [],
      total: 0,
      formInline: true
    };
  },
  created() {
    this.$api.getAllAdminPermissions().then(data => {
      this.permissions = data;
    });
  },
  mounted() {
  },
  methods: {
  }
};
</script>
